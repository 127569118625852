.form {
  width: 100%;
  margin-bottom: 20px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.logo {
  cursor: pointer;
  height: 50px;
  width: auto;
}

.welcome {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  margin: 24px 0 32px 0;
}

.errorMessageContainer {
  margin-bottom: 16px;
}

.alert {
  display: flex;
  flex-direction: column;
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 0 20px;
}

.input {
  margin-top: 24px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.arrowIcon {
  width: 24px;
  height: 14px;
  fill: var(--mui-palette-primary-main);
}

.arrowIconErr {
  composes: arrowIcon;
  fill: var(--mui-palette-error-main);
}

.errorButton {
  color: var(--mui-palette-error-main);
  margin-top: 8px;
  padding: 0;
}

.errorButtonRow {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.link {
  display: flex;
  align-items: center;
  color: var(--mui-palette-primary-main);
  font-size: 14px;
  font-weight: 700;
  margin-left: 4px;
}

.errorLink {
  composes: link;
  color: var(--mui-palette-error-main);
}

.resetLink {
  composes: link;
  color: var(--mui-palette-error-main);
  margin-left: 0px;
}

@media (max-width: 820px) {
  .signInSection {
    align-items: flex-start;
    padding: 32px 16px;
  }
}
