.wrapper {
  max-width: 420px;
  padding: 24px;
  border-radius: 5px;
}

.subheader {
  padding: 8px 0 20px 0;
}

.codeContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}

.alertContainer {
  margin-bottom: 24px;
}

.resendRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loading {
  padding-bottom: 32px;
}

.subtext {
  padding: 24px 0;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.modalIconContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 16px 0;
}

.modalIcon {
  height: 48px;
  width: 48px;
}
